<template>
    <div>
        <modal :name="modal_name" class="final-modal increase-width-popups-refund popup-modal-all-width-height-height-500" transition="nice-modal-fade">
            <div class="v-modal-content">
                <div class="v-modal-header">
                    <span class="v-modal-dialog-title v-modal-title text-white" >REFUND</span>
                    <span @click="hideRefundPop">
                        <i class="icon icon-close pointer text-white"></i>
                    </span>
                </div>
                <div class="general-section" style="padding: 15px 0px;">
                <div class="v-modal-body">
                    <div class="modal-layout-box d-flex align-items-center justify-content-center" v-if="ajax_loader">
                        <div class="digi-table-loader row justify-content-center" style="margin: 0 auto">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                    <div class="modal-layout-box" v-else>
                        <div class="d-flex align-items-center justify-content-between">
                            <h1 class="text-secondary" style="font-size: 15px;">Order ID:</h1>
                            <h1 class="fw-500 text-uppercase" style="color: #1d2126;font-size: 15px;">{{refund_details.order_number}}</h1>
                        </div>
                        <div class="refund-text">
                            <span class="fw-bold fs-16 text-secondary" style="line-height: 1;">
                                Refund
                            </span>
                        </div>
                        <div class="d-flex align-items-center justify-content-between">
                            <h1 class="fw-600 mb-0" style="color: #000;font-size: 15px;">Paid Total</h1>
                            <h1 class="fw-500 mb-0 text-success" style="font-size: 15px;">{{refund_details.paid_total_currency}}</h1>
                        </div>
                        <div class="hr-line my-3"></div>
                        <div class="refund-text">
                            <span class="fw-bold fs-16 text-secondary" style="line-height: 1;">
                                Mode of payment
                            </span>
                        </div>
                        <div class="d-flex align-items-center justify-content-between mt-3">
                            <h1 class="text-secondary mb-0" style="font-size: 15px;">{{refund_details.mode_of_payment}}</h1>
                            <span v-if="refund_details.mode_of_payment == 'CASH'">
                                <img width="30" src="/static/images/cash_icon.svg" alt="Cash" />
                            </span>
                            <span v-else>
                                <img width="30" src="/static/images/cards.svg" alt="Cash" />
                            </span>
                        </div>
                        <div class="hr-line my-3"></div>
                        <div class="refund-text mt-2">
                            <span class="fw-bold fs-16 text-secondary" style="line-height: 1;">
                                Comments
                            </span>
                        </div>
                        <div class="textarea-input">
                            <textarea class="w-100" rows="3" v-model="comments" placeholder="Write comments here"></textarea>
                        </div>
                    </div>
                </div>
                </div>
                <div class="v-modal-dialog-actions modal-bg text-center pt-5 d-block">
                    <button class="btn-refund w-100 pointer fw-600" :disabled="is_api_call_in_progress" @click="refundOrder">Refund {{refund_details.paid_total_currency}}</button>
                </div>
            </div>
        </modal>
        <sweet-modal ref="success_modal" icon="success">
            {{ modal_msg }}
        </sweet-modal>
        <sweet-modal ref="warning_modal" icon="warning">
            {{ modal_msg }}
        </sweet-modal>
    </div>
</template>
<script>
import { SweetModal } from "sweet-modal-vue";
import Avatar from 'vue-avatar'
import orderServices from './mixins/orders'
import printers from './mixins/printers';
import Swal from 'sweetalert2'
export default {
    mixins: [orderServices, printers],
    props:['modal_name', 'order_id'],
    data(){
        return{
            modal_msg:'',
            refund_details: {},
            comments: '',
            ajax_loader: false,
            is_api_call_in_progress: false
        }
    },
    components:{
        Avatar,
        SweetModal
    },
    methods:{
        hideRefundPop() {
            this.$emit('hideRefundPop');
        },
        async getRefundData() {
            this.ajax_loader = true;
            try {
                let params = {
                    order_id: this.order_id,
                };
                let response = await this.getRefundDetails(params);
                this.refund_details = response.response;
                console.log(this.refund_details.mode_of_payment)
                this.ajax_loader = false;
            }
            catch (error) {
                this.modal_msg = error;
                this.$refs.warning_modal.open();
                setTimeout(() => {
                    this.$refs.warning_modal.close();
                    this.hideRefundPop();
                }, 2000);
                // this.ajax_loader = false;
            }
        },
        async refundOrder() {
            this.is_api_call_in_progress = true;
            try {
                let params = {
                    order_id: this.order_id,
                    comments: this.comments,
                };
                let response = await this.saveRefundData(params);
                this.modal_msg = response.message;
                this.$refs.success_modal.open();
                setTimeout(() => {
                    this.$refs.success_modal.close();
                    this.hideRefundPop();
                    this.confirmPrint(response.receipt_details)
                }, 2000);
            }
            catch (error) {
                this.modal_msg = error;
                this.$refs.warning_modal.open();
                setTimeout(() => {
                    this.$refs.warning_modal.close();
                    this.is_api_call_in_progress = false;
                }, 2000);
            }
        },
        confirmPrint(receipt_data) {
            if(this.loggedInUser.print_receipt_by_default) {
                this.store_receipt_data = receipt_data
                // if(ticket_data && ticket_data.length){
                //     if(ticket_data.length == 1){
                //         this.store_ticket_data = ticket_data[0]
                //         if(!this.ticket_printing){
                //             this.printTicket(ticket_data[0])
                //         }
                //     }
                //     else if(ticket_data.length > 1){
                //         ticket_data.forEach(x => {
                //             this.store_ticket_data = x
                //             if(!this.ticket_printing){
                //                 this.printTicket(this.store_ticket_data)
                //             }
                //         })
                //     }
                // }
                this.printReceipt(receipt_data)
                if(this.loggedInUser.enable_double_receipts) {
                    setTimeout(() => {
                        this.printReceipt(receipt_data)
                    }, 5000);
                }
            } else {
                Swal({
                    title: 'Print Receipts',
                    text: 'Do You Want to Print Receipt ?',
                    type: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#00448b',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'Continue Without Printing',
                    allowOutsideClick: false
                }).then(result => {
                    if (result.value) {
                        this.store_receipt_data = receipt_data
                        // if(ticket_data && ticket_data.length){
                        //     if(ticket_data.length == 1){
                        //         this.store_ticket_data = ticket_data[0]
                        //         if(!this.ticket_printing){
                        //             this.printTicket(ticket_data[0])
                        //         }
                        //     }
                        //     else if(ticket_data.length > 1){
                        //         ticket_data.forEach(x => {
                        //             this.store_ticket_data = x
                        //             if(!this.ticket_printing){
                        //                 this.printTicket(this.store_ticket_data)
                        //             }
                        //         })
                        //     }
                        // }
                        this.printReceipt(receipt_data)
                        if(this.loggedInUser.enable_double_receipts) {
                            setTimeout(() => {
                                this.printReceipt(receipt_data)
                            }, 5000);
                        }
                    } else {
                        this.$modal.hide(this.modal_name)
                    }
                })
            }
        },
    },
    computed: {
        loggedInUser() {
            return this.$store.getters.loggedInUser;
        },
        save_data() {
            return {
                order_id: this.order_id,
                comments: this.comments,
            }
        }
    },
    created(){
    },
    mounted(){
        this.getRefundData();
    }
}
</script>
<style scoped>
.whiteSpace{
    white-space: nowrap;
}
.v-modal-layout{
    overflow: initial !important;
}
.final-modal .v-modal-header {
    background-color: #00448b !important;
}
.final-modal .v-modal-title {
    color: #fff !important;
}
.modal-layout-box {
    padding: 16px 12px 12.5px 11.8px;
    border-radius: 5px;
    box-shadow: 0 1.5px 3px 0 rgba(0, 0, 0, 0.16);
    border: solid 0.5px #206ad3;
    background-color: #fff;
}
.refund-text {
    margin: 0 0.3px 6.5px;
    padding: 2px 6px;
    background-color: #eeebeb;
}
.hr-line {
    border-top: dashed 2px #709fe3;
}
.textarea-input textarea {
    padding: 4.5px 4px 7.5px 4px;
    border-radius: 2px;
    border: solid 0.5px #cfcece;
    background-color: #fff;
    box-shadow: none;
}
.btn-refund{
    padding: 7px 16px 5.1px 16px;
    border-radius: 3px;
    background-image: linear-gradient(to left, #71e2a2, #07a163);
    outline: 0 none !important;
    box-shadow: none;
    color: #fff;
}
</style>